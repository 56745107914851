import React from "react";
import Kontakter from "../assets/info.json";

export default function Kontakt() {
  return (
    <div className="flex">
      <div className="w-[30%] mr-[5%]">
        <h3 className="text-xl tracking-widest font-bold mb-4">Kontakt</h3>
        <p>
          Har du spørgsmål til Ejerforeningen, ønsker du at kontakte formand,
          vicevært eller vores administrator så find email eller telefonnummer
          her. Her finder du også kontaktoplysninger på sædvanligt anvendte
          håndværkere.
        </p>
      </div>
      <div className="w-[65%]">
        <h4 className="font-bold text-xl mt-4 mb-2">Formand</h4>
        <p>{Kontakter?.Formand?.name}</p>
        <p>Mail: {Kontakter?.Formand?.email}</p>
        <p>Telefon: {Kontakter?.Formand?.mobile}</p>
        <h4 className="font-bold text-xl mt-4 mb-2">ViceVært</h4>
        <p>{Kontakter?.ViceVært?.name}</p>
        <p>Mail: {Kontakter?.ViceVært?.email}</p>
        <p>Telefon: {Kontakter?.ViceVært?.mobile}</p>
        <h4 className="font-bold text-xl mt-4 mb-2">Administrator</h4>
        <p>{Kontakter?.Administrator?.company}</p>
        <p>{Kontakter?.Administrator?.name}</p>
        <p>Mail: {Kontakter?.Administrator?.email}</p>
        <p>Telefon: {Kontakter?.Administrator?.mobile}</p>
        <h4 className="font-bold text-xl mt-4">
          Liste over sædvanligt anvendte håndværkere
        </h4>
        <p className="text-sm mb-2">Opdateret forår 2024</p>
        <p className="font-bold leading-7">
          VVS: {Kontakter?.VVS?.name}, {Kontakter?.VVS?.mobile}
        </p>

        

        <h5 className="font-bold text-center mt-10">OBS!</h5>
        <p className="font-bold">
          Hvis du kontakter ovennævnte håndværkere (eller andre) skal du selv
          betale for ydelsen, medmindre at du har en aftale med viceværten eller
          formanden.
        </p>
        <br></br>
        <p className="font-bold">
          Du må ikke lave arbejde på husets installationer uden aftale med
          vicevært eller formand.
        </p>
      </div>
    </div>
  );
}
