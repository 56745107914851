import React from "react";

export default function Bestyrelse_referater() {
  return (
    <div>
      <h3 className="text-xl tracking-widest font-bold mb-4">
        BESTYRELSESMØDER
      </h3>
      <p>Næste bestyrelsesmøde: 26. august 2019</p>
      <h3 className="text-xl tracking-widest font-bold mb-4">REFERATER</h3>
      <p>
        Referater fra tidligere møder er tilgængelige her. Åbnes i Onedrive.
      </p>
      <p>*referater fra 2017-2019 er på vej</p>
      <div className="flex">
        <a
          href="https://onedrive.live.com/?authkey=%21AEM5gAlT5ZBuCX4&id=C2F2D15FFBADDC2B%21142&cid=C2F2D15FFBADDC2B"
          target="_blank"
          rel="noreferrer"
          className="mt-6 mr-6 p-[40px] bg-blue-800 text-white"
        >
          2015
        </a>
        <a
          href="https://onedrive.live.com/?authkey=%21AIIRdcnIIcmFZok&id=C2F2D15FFBADDC2B%21642&cid=C2F2D15FFBADDC2B"
          target="_blank"
          rel="noreferrer"
          className="mt-6 p-[40px] bg-blue-800 text-white"
        >
          2016
        </a>
      </div>
    </div>
  );
}
