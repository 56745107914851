import React from "react";
import OmOS from "../assets/images/omOs1.jpg"
import OmOS2 from "../assets/images/omOs2.jpg"

export default function OmLouisegaarden() {
  return (
    <div className="flex">
      <div className="w-[65%] mr-[5%]">
        <h3 className="text-xl tracking-widest font-bold mb-4">
          Velkommen til Ejerforeningen Louisegaarden!
        </h3>
        <p>
          Vores ærværdige gamle ejendom er fra 1925 og består i dag af 24
          lejligheder og fire butikker fordelt på to opgange i forhuset, samt ni
          llejligheder i det renoverede baghus. De fleste lejligheder i forhuset
          har altaner ud mod baggården.
        </p>
        <p>
          Til disposition for såvel for- som baghuset er der desuden et mindre,
          urbant gårdmiljø bestående af to store "tagterrasser" i første sals
          højde med tilhørende møbler og beplantning. I baggården er der desuden
          nogle få parkeringspladser.
        </p>
        <br></br>
        <p>
          Louisegaarden har facade ud mod Åboulevarden og Aarhus Å, der løber
          lige nedenfor vore vinduer.
        </p>
        <br></br>
        <p>
          I 2011/2012 gennemgik Ejerforeningen Louisegaarden en større
          renovering: facaden i baghuset blev malet, der kom helt nyt tag på
          forhuset og de to opgange i forhuset blev renoveret fra a til z. For
          2013/2014 er der allerede planlagt udskiftning af vinduer i forhuset
          samt en gennemgribende facaderenovering, så ejendommen kommer helt til
          sin ret på en af byens bedste adresser!
        </p>
        <br></br>
        <p>
          I kombination med Aarhus Å, der løber parallelt med Åboulevarden og
          vores ejendom, indgår Louisegaarden markant i hele området. Resultatet
          er et lækkert miljø med nye spændende butikker, caféer og
          restauranter!
        </p>
        <br></br>
        <p>Adresse: Åboulevarden 51-53, Aarhus 8000, Danmark</p>
      </div>
      <div className="w-[25%] ml-[5%]">
      <img src={OmOS} alt="om os" className="w-[80%]" />
      <img src={OmOS2} alt="om os 2" className="w-[80%]" />
      </div>
    </div>
  );
}
