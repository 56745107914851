import Navbar from "./Navbar";
import Forside from "./pages/Forside";
import Husorden from "./pages/Husorden";
import OmLouisegaarden from "./pages/OmLouisegaarden";
import BestyrelseReferater from "./pages/Bestyrelse_referater";
import Links from "./pages/Links";
import Kontakt from "./pages/Kontakt";
import { Route, Routes } from "react-router-dom";
import Footer from "./Footer";

function App() {
  return (
    <div className="w-[1200px] m-auto">
      <header>
        <Navbar />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Forside />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/husorden" element={<Husorden />} />
          <Route path="/omLouisegaarden" element={<OmLouisegaarden />} />
          <Route
            path="/bestyrelse_referater"
            element={<BestyrelseReferater />}
          />
          <Route path="/links" element={<Links />} />
        </Routes>
      </main>
      <footer className="">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
