import React from "react";

export default function Links() {
  return (
    <div className="flex flex-col">
      <h3 className="text-xl tracking-widest font-bold mb-4">
        Fællesråd for Møllepark-Vestergades Kvarteret
      </h3>
      <p>
        Er du interesseret i at deltage aktivt i lokalemiljøet så har du
        muligheden igennem Møllepark-Vestergades fællesråd. Det er støttet af
        Århus Kommune. Du kan læse mere om dette på nedenstående link.
      </p>
      <a
        href="http://xn--moelleparkfllesraad-vxb.dk/"
        target="_blank"
        rel="noreferrer"
        className="mt-4 underline text-blue-800"
      >
        Møllepark-Vestergades Kvarteret
      </a>
      <a
        href="https://aarhus.dk/da/politik/raad-og-naevn/faellesraad-for-borgere/faellesraadsportalen.aspx/"
        target="_blank"
        rel="noreferrer"
        className="mt-4 underline text-blue-800"
      >
        Århus Kommune Fællesråd
      </a>
    </div>
  );
}
