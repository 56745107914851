import React from "react";

export default function Footer() {
  return (
    <div className="mt-[5vh]">
      <div className="border border-black w-full"></div>
      <div>© 2015 Louisegaarden Aarhus</div>
    </div>
  );
}
