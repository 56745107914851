import React from "react";
import Home1 from "../assets/images/home1.jpg";
import Home2 from "../assets/images/home1.jpg";
import { Link } from "react-router-dom";

export default function Forside() {
  return (
    <div>
      <h2 className="text-3xl tracking-widest font-bold mb-4">LOUISEGAARDEN</h2>
      <div className="flex">
        <div className="w-[65%]">
          <img src={Home1} alt="home1" />
        </div>
        <div className="flex flex-col justify-center items-center w-[35%] p-[2%]">
          <Link
            to="/kontakt"
            className="my-[2%] p-2 w-[250px] border border-black py-[4%] homeButtons"
          >
            Kontakt Formand eller Vicevært
          </Link>
          <Link
            to="/husorden"
            className="my-[2%] p-2 w-[250px] border border-black py-[4%] homeButtons"
          >
            I tvilt om regler - tjek Husorden
          </Link>
          <Link
            to="/kontakt"
            className="my-[2%] p-2 w-[250px] border border-black py-[4%] homeButtons"
          >
            Se ofte benyttet håndværker
          </Link>
        </div>
      </div>
      <div className="flex mt-[4vh] items-center">
        <div className="w-[30%]">
          <img src={Home2} alt="home2" className="w-full" />
        </div>
        <div className="ml-4 w-[50%]">
          <p>
            Vores ærværdige gamle ejendom er fra 1925 og består i dag af 24
            lejligheder og fire butikker fordelt på to opgange i forhuset samt
            ni lejligheder i det renoverede baghus. Louisegaarden har facade ud
            mod Åboulevarden og Aarhus Å, der løber lige nedenfor vore vinduer.
          </p>
        </div>
      </div>
    </div>
  );
}
