import React from "react";
import PDFImage from "../assets/images/pdfImage.webp";
import PDF1 from "../assets/pdf/Husorden.pdf";
import PDF2 from "../assets/pdf/Tillæg_til_husorden.pdf";
import PDF3 from "../assets/pdf/Husorden_Louisegaarden_tillæg.pdf";
import PDF4 from "../assets/pdf/Normalvedtægt_Louisegaarden.pdf";

export default function Husorden() {
  return (
    <div>
      <h3 className="text-xl tracking-widest font-bold mb-4">Husorden</h3>
      <p>
        Ejerforeningen Louisegaarden er en livlig ejerforening. Her bor unge,
        gamle, singler, par, studerende i bofællesskaber og nybagte forældre.
        Hvad enten vi er lejere eller ejere af de lejligheder, vi bor i, er det
        vigtigt, at vi selv sørger for god ro og orden samt at vi respekterer
        hinanden. Du kan downloade den senest reviderede udgave af vores
        husorden, som desuden indeholder praktiske oplysninger om håndværkere,
        affald, vicevært, lån af havegrill m.m. Husorden og Retningslinier er
        vedtaget på generalforsamling den 12. marts 2012.
      </p>
      <div className="border border-black flex w-1/2 m-auto py-[3%] px-[1%]">
        <a
          href={PDF1}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col justify-center items-center w-full"
        >
          <img src={PDFImage} alt="pdf" />
          Husorden
        </a>
        <a
          href={PDF2}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col justify-center items-center w-full"
        >
          <img src={PDFImage} alt="pdf" />
          Tillæg ervherv
        </a>
        <a
          href={PDF3}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col justify-center items-center w-full"
        >
          <img src={PDFImage} alt="pdf" />
          Tillæg
        </a>
        <a
          href={PDF4}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col justify-center items-center w-full"
        >
          <img src={PDFImage} alt="pdf" />
          Normalvedtægt
        </a>
      </div>
      <div>
        <h5>Storskrald</h5>
        <p>
          Storskrald afhentes af Aarhus kommune. Du skal selv sørge for at
          bestille afhentning og placere skraldet som angivet af kommunen.
          Bestil afhentning af storskrald{" "}
          <a
            href="https://affaldvarme.aarhus.dk/Error.html?aspxerrorpath=/affald-og-genbrug/storskrald/https:/affaldvarme.aarhus.dk/affald-og-genbrug/storskrald/"
            className="underline text-blue-800"
          >
            Her
          </a>
          .
        </p>
        <p>
          Genstandene skal stilles på fortorvet (ikke i baggården) senest kl.
          06.00 om morgenen på afhentningsdagen, eller evt. aftenen før.
        </p>
      </div>
    </div>
  );
}
