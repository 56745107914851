import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import Logo from "./assets/images/logo.webp";

export default function Navbar() {
  return (
    <nav className="flex justify-around items-center h-[10vh] mb-[5vh] mt-[20px]">
      <div className="text-center">
        <img src={Logo} alt="logo" />
        <h1>
          Ejerforening <br></br> <span className="text-xl">Louisegaarden</span>
        </h1>
      </div>
      <ul className="flex justify-around">
        <CustomLink to="/">Forside</CustomLink>{" "}
        <CustomLink to="/kontakt">Kontakt</CustomLink>
        <CustomLink to="/husorden">Husorden</CustomLink>
        <CustomLink to="/omLouisegaarden">Om Louisegaarden</CustomLink>
        <CustomLink to="/bestyrelse_referater">
          Bestyrelsesmøder og referater
        </CustomLink>
        <CustomLink to="/links">Links</CustomLink>
      </ul>
    </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({path: resolvedPath.pathname, end: true})
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
